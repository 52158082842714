export const customURL = {
  cartUrl: '/cart',
  homeUrl: '/',
  checkout: '/checkout',
  guestCheckout: '/scs/checkout',
};

export const trustedSiteVerify = {
  verify: 'https://www.trustedsite.com/verify?js=1&host=shopmyexchange.com&lang=EN',
};

export const PIA = {
  link: 'https://www.trustedsite.com/verify?js=1&host=shopmyexchange.com&lang=EN',
  defense: 'https://dpcld.defense.gov/Privacy/SORNsIndex/DOD-Component-Notices/Army-Article-List/',
};

export const PER_PAGE_STORE = 4;

export const HEADER_HEIGHT = 145;

export const PICKERS_MAX_COUNT = 5;

export const PICKERS_MIN_COUNT = 3;

export const EGIFT_CARD = ['EGIFT_CARD'];

export const PRODUCT_TYPES_WITH_QUANTITY_RESTRICTION = ['EGIFT_CARD', 'POSA_GIFT'];

export const EGIFT_CUSTOM_PRODUCTS = ['EGIFT_CARD', 'POSA_GIFT', 'DONATIONS', 'EPP'];

export const VIA_EMAIL_PRODUCTS = ['EGIFT_CARD', 'POSA_GIFT', 'EPP'];

export const PHYSICAL_GIFT_CARDS = ['GIFT_CARD'];

export const POSA_GIFT = ['POSA_GIFT'];

export const DONATION_ITEMS = 'DONATIONS';

export const MILSTAR_CARD = 'Milstar';

export const GIFT_CARD_TYPE = 'giftCard';

export const NETWORKS = ['facebook', 'twitter', 'pinterest'];

export const SHARETHISURL = 'https://platform-api.sharethis.com/js/sharethis.js?';

export const MARKETPLACE_PRODUCT = 'INTEGRATED_VENDOR';

export const CUSTOM_PRODUCT_TYPE = 'customProductType';

export const COMING_SOON = 'COMING_SOON';

export const NON_PURCHASABLE_PRODUCT_TYPES = ['NON_PURCHASABLE'];

export const VISIBLE_THUMBNAILS_COUNT = 5;

export const GIFT_CARDS = ['EGIFT_CARD', 'GIFT_CARD', 'POSA_GIFT'];

export const PRODUCT_TYPES_WITH_NO_SHIPPING_OPTIONS = ['EGIFT_CARD', 'GIFT_CARD', 'DONATIONS', 'POSA_GIFT', 'EPP'];

export const UB_GENDERS = [
  { name: 'Male', value: 'Men' },
  { name: 'Female', value: 'Women' },
];

export const UB_RANKS = ['Enlisted', 'Warrant Officer', 'Officer', 'General Officer'];

export const PICKER_COLOR = 'Color';

export const BUNDLE_ITEMS = ['PRODUCT_UNIFORM'];
export const BUNDLE_ITEM = 'PRODUCT_UNIFORM';

export const UB_PRODUCT_GROUPS_IMAGE_BASE_URL = 'https://media.shopmyexchange.com/is/image/aafes/';

export const UB_PATCH_OPTIONS = [
  { value: 'Sew-On', label: 'Sew on' },
  { value: 'Hook & Loop', label: 'Hook and Loop' },
];

export const UB_SEW_ON_ELIGIBLE_ATTACHMENT_TYPES = ['Sew-On', 'SewOn & HookLoop'];

export const UB_INSIGNIA_METHOD_TEXT_PROPS = {
  heading: 'Insignia/Rank Attachment Method:',
  info: 'A fee will apply for sewing patches.',
  note: 'Note: Your patch selections will not be saved when the attachment method is changed.',
};

export const UB_PRODUCT_TYPE_SINGLE = 'single';
export const UB_PRODUCT_TYPE_MULTI = 'multi';
export const UB_PRODUCT_TYPE_PERSONALIZE = 'personalize';
export const UB_REQUIRED_VALIDATION_TEXT = 'One or more required selections must be made above';
export const UB_PRODUCT_NOT_FOUND_TEXT = 'No Product Found';
export const UB_ADD_TO_CART_TEXT = 'Add to Cart';
export const UB_UPDATE_TO_CART_TEXT = 'Update Bundle';
export const UB_ATTACHMENT_CHANGE_PROMPT_TEXT = 'Are you sure?';
export const UB_OPTIONAL_FIELD_TEXT = '(Optional)';
export const UB_SELECT_UPTO_TEXT = 'Select upto';
export const UB_SELECT_TYPE_TEXT = 'patches';
export const UB_PRODUCT_TYPE_MULTI_INFO_TEXT =
  'We will attach badges to your uniform based on the order in which you select them.';
export const UB_REQUIRED_FIELD_TEXT = '*';
export const UB_PERSONALIZE_INPUT_MAX_LENGTH = 18;
export const UB_PERSONALIZE_INPUT_UPDATE_TEXT = 'Change Name';
export const PDP_IMAGE_ZOOM_PROPS = {
  magnifiedContainerSize: 500,
  magnifierSize: 166,
  maxZoom: 3,
  minZoom: 10,
};
export const UB_MAIN_IMAGE_ZOOM_PROPS = {
  ...PDP_IMAGE_ZOOM_PROPS,
};
export const UB_APPAREL_IMAGE_ZOOM_PROPS = {
  magnifiedContainerSize: 500,
  magnifierSize: 50,
  maxZoom: 8,
  minZoom: 10,
};
export const UB_APPAREL_CONFIG_IMAGE_PROPS = {
  magnifiedContainerSize: 500,
  magnifierSize: 50,
  maxZoom: 7,
  minZoom: 10,
};

export const UB_APPAREL_MAXBUTTON = 6;
export const APO_FPO_STATES_MATCH = ['AA', 'AE', 'AP'];
export const APO_FPO_CITIES_MATCH = ['apo', 'fpo', 'dpo'];
export const PAGEVIEW_EVENT = 'page_view';
export const GA_PAGE_VIEW_DATALAYER_READY = 'datalayer_ready';
export const YOU_MAY_ALSO_LIKE_DESKTOP_VIEW = 5;
export const YOU_MAY_ALSO_LIKE_TABLET_VIEW = 4;
export const YOU_MAY_ALSO_LIKE_MOBILE_VIEW = 2;
export const GA_GIFT_CARDS = 'Gift Cards';
export const GA_SORT = 'sort';
export const GA_EXCHANGE = 'The Exchange';
export const GA_APPLY_FILTER = 'apply_filter';
export const PRODUCT_DETAIL_VIEW_EVENT = 'productDetailView';
export const CART_EVENT = 'cart';
export const REFERRER_EVENT = 'referrer';
export const SCREENSIZE_EVENT = 'screenSize';
export const CART_PRODUCT_DETAIL_VIEW_EVENT = 'cartProductDetailView';
export const PURCHASE_EVENT = 'purchase';
export const DEFAULT_MILSTAR_PLAN_NUMBER = '10001';
export const MILITARY_ITEMS_PLAN_NUMBER = '20001';
export const AMOUNT_ELIGIBLE_FOR_MILSTAR_PYW_INSTALLMENTS = 30000;

export const GA_ADD_PAYMENT_INFO = 'add_payment_info';
export const GA_ADD_SHIPPING_INFO = 'add_shipping_info';
export const GA_ADD_TO_CART = 'add_to_cart';
export const GA_ADD_TO_WISHLIST = 'add_to_wishlist';
export const GA_BEGIN_CHECKOUT = 'begin_checkout';
export const GA_PURCHASE = 'purchase';
export const GA_REMOVE_FROM_CART = 'remove_from_cart';
export const GA_VIEW_CART = 'view_cart';
export const GA_VIEW_ITEM = 'view_item';
export const GA_VIEW_PROMOTION = 'view_promotion';
export const GA_SELECT_PROMOTION = 'select_promotion';
export const GA_LOGIN = 'login';
export const GA_SIGN_UP = 'sign_up';
export const GA_MY_ACCOUNT = 'my_account';
export const GA_ACCOUNT = 'Account';
export const GA_SHOP_BY_CATEGORY = 'Shop By Category';
export const GA_MAIN_MENU = 'Main Menu';
export const GA_ADDRESS_BOOK = 'address_book';
export const GA_COMMUNICATION_PREFERENCES = 'communication_preferences';
export const GA_PROFILE_INFORMATION = 'profile_information';
export const GA_ORDER_HISTORY = 'order_history';
export const GA_ACCOUNT_SUBSCRIPTIONS = 'account_subscriptions';
export const GA_WISHLIST = 'wishlist';
export const GA_WALLET = 'wallet';
export const GA_SUBSCRIPTION = 'subscriptions';
export const GA_UNIFORM_BUILDER = 'uniform_builder';
export const GA_VIEW_ITEM_LIST = 'view_item_list';
export const GA_VIEW_CLP = 'view_clp';
export const GA_SELECT_ITEM = 'select_item';
export const GA_INITIATE_CHECKOUT = 'initiate_checkout';
export const GA_CONTINUE_CHECKOUT = 'continue_checkout';
export const GA_ORDER_REVIEW = 'continue_to_order_review';
export const GA_CONTINUE_TO_PAYMENT = 'continue_to_payment';
export const GA_SEARCH_RESULT = 'search_results';
export const GA_CONTINUE_TO_MARKETPLACE = 'continue_to_marketplace';
export const GA_ERROR_PAGE = 'error_page';
export const GA_PAGE_VIEW = 'page_view';
export const GA_SERVER_URL = 'www.shopmyexchange.com';
export const GA_SELECT_CONTENT = 'select_content';
export const GA_CONTEST = 'Contest';
export const GA_SIGN_IN = 'Sign In';
export const GA_VIEW_SEARCH_RESULTS = 'view_search_results';
export const GA_TYPE_SEARCH_RESULT = 'Search Results';
export const GA_EXCHANGE_CONTEXTUAL_MENU = 'The Exchange Contextual Menu';
export const GA_EXCHANGE_PAGE = 'The Exchange Page';
export const GA_STATUS = 'status';
export const GA_FIND_STORE_MODAL = 'Find a Store Modal';
export const GA_FIND_STORE = 'Find a Store';
export const GA_TYPE_PRODUCT_CATEGORY = 'Product Categories';
export const GA_TYPE_SAVED_FOR_LATER = 'Saved for Later';
export const GA_TYPE_COMPARE_PRODUCT = 'Product Comparison';
export const GA_TYPE_YOU_MAY_LIKE = 'you may also like';
export const GA_TYPE_REGULAR_CHECKOUT = 'Regular Checkout';
export const GA_TYPE_GUEST_CHECKOUT = 'Guest Checkout';
export const GA_CHECKOUT_TYPE_REGULAR = 'Regular';
export const GA_CHECKOUT_TYPE_GUEST = 'Guest';
export const GA_PROMOTIONS = 'Promotions';
export const GA_ORDERS = 'Orders';
export const GA_REVIEW_ORDER = 'review_order';
export const GA_FORM_COMPLETE = 'form_complete';
export const GA_ADD_CREDIT_CARD = 'Add Credit Card';
export const GA_EDIT_CREDIT_CARD = 'Edit Credit Card';
export const GA_CHECK_GIFT_BAL = 'Check Gift Balance';
export const GA_ADD_NEW_ADDRESS = 'Add New Address';
export const GA_EDIT_ADDRESS = 'Edit Address';
export const GA_UPDATE_COM_PREF = 'Update Communication Preferences';
export const GA_PROFILE = 'Profile';
export const GA_PAID_PLACEMENT = 'Paid Placement';
export const GA_AAFES_PRODUCTS = 'AAFES Products';
export const GA_MARKETPLACE = 'MarketPlace';
export const GTM_ADDRESS_BOOK = 'Address Book';
export const GTM_WALLET = 'Wallet';
export const GTM_PRODUCT = 'Products';
export const GTM_HEADER = 'Header';
export const GTM_FOOTER = 'Footer';
export const GTM_MAIN_MENU = 'Main Menu';
export const GTM_SIDEBAR = 'Side Bar';
export const GTM_MY_ACC = 'My Account';
export const GTM_BREADCRUMB = 'Breadcrumb';
export const GTM_PAGE = 'Page';
export const GTM_RUNTIME_JS_EXCEPTION = 'Runtime JS Exception';
export const GTM_MILITARY_STAR = 'Military Star Pop Up';
export const GTM_MY_ACCOUNT = 'My Account Pop Up';
export const GTM_GENERIC = 'Generic';
export const GTM_MARKET_PLACE = 'Market Place';
export const GTM_WISHLIST = 'Wishlist';
export const GTM_SHARE = 'Share';
export const GTM_CONTEST = 'Contest';
export const GA_BANNER = 'Banner';
export const GA_SWEEPSTAKES = 'Sweepstakes';
export const GTM_MARKETPLACE_SITES = 'Marketplace Sites';
export const GTM_SOCIAL_NETWORK = 'Social Networks';
export const GTM_UNIFORM_BUILDER = 'uniform builder';
export const FORM_PROGRESS = 'form_progress';
export const AAFES_OWNED_SITE = 'AAFES Owned Sites';
export const NON_AAFES_OWNED_SITE = 'Non - AAFES Sites';
export const CUSTOM_PRODUCT_TYPES_TO_FILTER_ELECTRONIC_DELIVERY_SHIPPING_ADDRESS = [
  'EGIFT_CARD',
  'POSA_CARD',
  'DONATIONS',
];

export const SIZE_CHART_TO_DISPLAY = 'Size';

export const PDP_PERSONALIZATION_LINES_MAX_CHARS = 18;

export const MARKETING_BUG_NAMES = {
  sale: 'SALE',
  popular: 'BEST SELLER',
  newItem: 'NEW',
  ourPick: 'OUR PICK',
  onlineOnly: 'ONLINE ONLY',
};

export const PDP_PROMOTIONS_CONFIG = {
  showMoreText: 'More Offers',
  maxPromotionsToShow: 3,
};

export const MAX_FILTER = 7;
export const MAX_COLOR = 4;
export const CATEGORY = 'Category';
export const PAGE_VIEW = { mobileView: 6, desktopView: [36, 72, 120] };
export const ALPHA = [
  '#',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const BRAND_SCROLL_DURATION = 1000;

export const SNARE_CONFIG = {
  src: 'https://mpsnare.iesnare.com/snare.js',
  type: 'text/javascript',
  async: true,
  io_bbout_element_id: 'ioBlackBox',
  io_install_stm: false,
  io_exclude_stm: 12,
  io_install_flash: false,
  io_enable_rip: true,
  sent: false,
};

export const PUIS_VAS_PRODUCT_TYPES = ['EPP', 'APPLECARE', 'FURNPLAN', 'HANDY_SERVICES', 'HAUL_AWAY'];

export const RESTRICTED_PATHS_UPON_LOGIN = ['/checkout', '/order-confirmation'];

export const CONTAINS_KEYWORDS = (text: string, keywords: string[]) => {
  return keywords.some((keyword) => text.includes(keyword));
};

export const VETERAN_STATUS_CODES = ['G'];

export const FRONT_SMALL = 3;

export const EXTPRICE_BRONZE = {
  fractionDigits: 2,
  centAmount: 0,
  currencyCode: 'USD',
};

export const TENDER_TYPE = 'MilStar';
export const MEGAMENU_CMS_PATH_EXTENSION = '.json';
