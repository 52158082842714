import {
  getAccount,
  changePassword,
  confirm,
  requestConfirmationEmail,
  login,
  logout,
  register,
  requestResetUsername,
  verifySSN,
  requestPasswordReset,
  updateCompareOTP,
  resetPassword,
  update,
  addAddress,
  updateAddress,
  removeAddress,
  setDefaultBillingAddress,
  setDefaultShippingAddress,
  defaultStoreAddress,
  listingStores,
  getChannelsSearch,
  updateCustomerPreferences,
  compareUserNameOTPWithToken,
  getBranchesOfService,
  getCcdValidationCheck,
  createAccount,
  confirmAccount,
  getOrderDetail,
  accountChangePassword,
  getNewCreditCards,
  deleteCreditCards,
  addCreditCards,
  updateCreditCards,
  getCMSTemplate,
  isEmailExist,
  cancelSubscriptionOrder,
  skipNextSubscriptionOrder,
  getSubscriptionTemplateOrders,
  getSubscriptionOrders,
  defaultStoreByGeo,
  updateSubscriptionPayment,
  updateSubscriptionShippingAddress,
  updateSubscriptionBillingAddress,
  updateSubscriptionFrequency,
  returnItem,
  itemReturnLabel,
  sendWishlistTemplate,
  validateSweepstake,
  submitSweepstake,
  confirmDeleteAccount,
  addSearchTerm,
  getSearchTerms,
  clearSearchTerms,
  vetVerify,
  dD214Upload,
  getCreditCards,
  getMaoChatUrl,
  getCustomerPreferenceBraze,
  savePreviewDateInSession,
  getPreviewDate,
} from '../../actions/account';
import {
  cartItems,
  addItem,
  checkout,
  orderConfirmation,
  orderHistory,
  cancelOrder,
  removeItem,
  removeMarketplaceItem,
  getShippingMethods,
  setShippingMethod,
  updateShippingType,
  moveToSaveForLater,
  getShippingForLineItem,
  updateLineItemShippingDetails,
  updateMarketplaceLineItemShippingDetails,
  updateCart,
  updateItem,
  redeemDiscountCode,
  removeDiscountCode,
  getProjectSettings,
  getProjectConfiguration,
  getDonationProducts,
  markAsGift,
  updateShippingAddress,
  addAdditionalItem,
  removeAdditionalItem,
  addSubscriptionService,
  removeSubscriptionService,
  checkGiftCardBalance,
  addGiftCardPayment,
  removePayment,
  addNewCreditCard,
  getNewCreditCard,
  setBillingAddress,
  addCreditCardForPayment,
  addBundle,
  addEGiftCardInformation,
  pickUpPersonInformation,
  removeBundle,
  updateBundle,
  updateBundleQuantity,
  updateBundleShippingAddress,
  guestCheckout,
  updateGuestShippingAddress,
  returnClaimForm,
  returnEmailInstructions,
  reCalculatePayments,
  proceedToCheckout,
  getStorePrice,
  getCartInventory,
  validateInventory,
  removeVAS,
  getAllConfigs,
  fetchDefaultPayment,
  getOrderByCartId,
} from '../../actions/cart';
import {
  getWishlist,
  addToWishlist,
  removeLineItem,
  updateLineItem,
  getPriceforWishlist,
} from '../../actions/wishlist';
import { createSession } from '../../actions/adyen';
import { UseAccount } from './UseAccount';
import { UseCart } from './UseCart';
import { UseWishlist } from './UseWishlist';
import { UseAdyen } from './UseAdyen';
import { UseSaveForLater } from './UseSaveForLater';
import {
  getSaveForLater,
  addToSaveForLater,
  removeSaveForLaterItem,
  updateSaveForLaterItem,
  moveToWishlist,
  moveToCart,
  getPriceForSaveforLater,
} from '../../actions/saveForLater';
import { getCustomMessage, getCustomMessages } from '../../actions/customMessage';
import { UseCustomMessage, UseCustomMessages } from './UseCustomMessage';
import {
  searchProduct,
  autoSuggestSearch,
  selectAvailableUniforms,
  getSkusExternalPrices,
  marketPlaceDetails,
  getMarketingBugProducts,
  fetchProductsByProductGroups,
  getShipToAddressInventoriesAvailability,
  getDroplets,
  getAltImages,
} from '../../actions/product';
import { UseProduct } from './UseProduct';
import { useState } from 'react';

export interface FrontasticState {
  useCustomMessage: UseCustomMessage;
  useCustomMessages: UseCustomMessages;
  useCart: UseCart;
  useAccount: UseAccount;
  useWishlist: UseWishlist;
  useAdyen: UseAdyen;
  useSaveForLater: UseSaveForLater;
  useProduct: UseProduct;
}

export const getFrontasticState = (): FrontasticState => {
  const [isMiniCartOpen, setIsMiniCartOpen] = useState(false);
  const [isAddItemAfterLogin, setIsAddItemAfterLogin] = useState(false);
  const [isCartProductDetailEventSent, setCartProductDetailEventSent] = useState(false);
  const [isProductDetailPageEventSent, setProductDetailPageEventSent] = useState(false);
  const [expandRatingReviews, setExpandingRatingReviews] = useState<boolean>(false);
  const [isMarketPlacePdp, setIsMarketPlacePdp] = useState<boolean>(false);
  const { loggedIn, account, error, accountLoading } = getAccount();
  const [orderConfirmationDetails, setOrderConfirmationDetails] = useState(null);
  return {
    useCart: {
      ...cartItems(),
      ...getAllConfigs(),
      addItem,
      orderConfirmation,
      updateCart,
      removeItem,
      removeMarketplaceItem,
      updateItem,
      updateShippingAddress,
      updateShippingType,
      getShippingForLineItem,
      updateLineItemShippingDetails,
      updateMarketplaceLineItemShippingDetails,
      getShippingMethods,
      setShippingMethod,
      moveToSaveForLater,
      checkout,
      orderHistory,
      cancelOrder,
      getProjectSettings,
      getProjectConfiguration,
      getDonationProducts,
      redeemDiscountCode,
      removeDiscountCode,
      markAsGift,
      addAdditionalItem,
      removeAdditionalItem,
      addSubscriptionService,
      removeSubscriptionService,
      checkGiftCardBalance,
      addGiftCardPayment,
      removePayment,
      addNewCreditCard,
      getNewCreditCard,
      setBillingAddress,
      addCreditCardForPayment,
      addBundle,
      addEGiftCardInformation,
      pickUpPersonInformation,
      removeBundle,
      updateBundle,
      updateBundleQuantity,
      updateBundleShippingAddress,
      guestCheckout,
      updateGuestShippingAddress,
      isMiniCartOpen,
      setIsMiniCartOpen,
      returnClaimForm,
      returnEmailInstructions,
      reCalculatePayments,
      proceedToCheckout,
      getStorePrice,
      isCartProductDetailEventSent,
      setCartProductDetailEventSent,
      isProductDetailPageEventSent,
      setProductDetailPageEventSent,
      getCartInventory,
      validateInventory,
      removeVAS,
      fetchDefaultPayment,
      isAddItemAfterLogin,
      setIsAddItemAfterLogin,
      getOrderByCartId,
    },
    useAccount: {
      loggedIn,
      account,
      error,
      addresses: account?.addresses ?? [],
      isAddressesLoading: accountLoading,
      login,
      logout,
      register,
      confirm,
      requestConfirmationEmail,
      changePassword,
      requestPasswordReset,
      resetPassword,
      update,
      addAddress,
      updateAddress,
      removeAddress,
      setDefaultBillingAddress,
      setDefaultShippingAddress,
      defaultStoreAddress,
      listingStores,
      getChannelsSearch,
      updateCustomerPreferences,
      compareUserNameOTPWithToken,
      requestResetUsername,
      updateCompareOTP,
      getBranchesOfService,
      getCcdValidationCheck,
      verifySSN,
      createAccount,
      confirmAccount,
      getOrderDetail,
      accountChangePassword,
      getNewCreditCards,
      deleteCreditCards,
      addCreditCards,
      updateCreditCards,
      getCMSTemplate,
      isEmailExist,
      skipNextSubscriptionOrder,
      cancelSubscriptionOrder,
      getSubscriptionOrders,
      getSubscriptionTemplateOrders,
      defaultStoreByGeo,
      updateSubscriptionPayment,
      updateSubscriptionShippingAddress,
      updateSubscriptionBillingAddress,
      updateSubscriptionFrequency,
      returnItem,
      itemReturnLabel,
      sendWishlistTemplate,
      validateSweepstake,
      submitSweepstake,
      confirmDeleteAccount,
      addSearchTerm,
      getSearchTerms,
      clearSearchTerms,
      vetVerify,
      dD214Upload,
      ...getCreditCards(loggedIn),
      orderConfirmationDetails,
      setOrderConfirmationDetails,
      getMaoChatUrl,
      getCustomerPreferenceBraze,
      savePreviewDateInSession,
      ...getPreviewDate(),
    },
    useWishlist: {
      ...getWishlist(loggedIn),
      addToWishlist,
      removeLineItem,
      updateLineItem,
      getPriceforWishlist,
    },
    useSaveForLater: {
      ...getSaveForLater(loggedIn),
      addToSaveForLater,
      removeSaveForLaterItem,
      updateSaveForLaterItem,
      moveToWishlist,
      moveToCart,
      getPriceForSaveforLater,
    },
    useAdyen: {
      createSession,
    },
    useCustomMessage: {
      getCustomMessage,
    },
    useCustomMessages: {
      getCustomMessages,
    },
    useProduct: {
      searchProduct,
      autoSuggestSearch,
      selectAvailableUniforms,
      fetchProductsByProductGroups,
      getSkusExternalPrices,
      marketPlaceDetails,
      getMarketingBugProducts,
      expandRatingReviews,
      setExpandingRatingReviews,
      getSTAInventories: getShipToAddressInventoriesAvailability,
      getDroplets,
      getAltImages,
      isMarketPlacePdp,
      setIsMarketPlacePdp,
    },
  };
};
