import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MdOutlineEmail, MdOutlinePhone } from 'react-icons/md';
import { mutate } from 'swr';
import WarningIcon from 'components/icons/warning';
import { GA_MY_ACCOUNT } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import CalculateExpDate from 'helpers/utils/CalculateExpDate';
import { isVeteranAccount } from 'helpers/utils/isVeteranAccount';
import { sendToMonetate } from 'helpers/utils/monetateHelper';
import { profileLogo } from 'helpers/utils/profileLogo';
import RenderCardsImages from 'helpers/utils/renderCardsImages';
import { tablet } from 'helpers/utils/screensizes';
import { useAccount, useCart } from 'frontastic';
import FindStore from '../../../../commercetools-ui/aafes/Modals/findStore';
import msc from '../../../../images/military-star-cardholders.png';
import MilStarCardSection from './militaryStarCardSection';

const AccountContent = ({ cardData, projectConfiguration }) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });
  const [loginModal, setLoginModal] = useState(false);
  const [findStoreModal, setFindStoreModal] = useState(false);
  const { loggedIn, listingStores, getSubscriptionTemplateOrders } = useAccount();
  const [storeList, setStoresList] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [subscriptionError, setSubscriptionError] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const { addresses } = useAccount();
  const [milStarCard, setMilStarCard] = useState(null);
  const { account, getCreditCards } = useAccount();
  const [allCards, setAllCards] = useState([]);
  const [orderHistoryData, setOrderHistoryData] = useState(null);
  const [accountData, setAccountData] = useState(false);
  const [cardDisplayNames, setCardDisplayNames] = useState({});

  const formatNextDeliveryDate = (deliveryDate: string) => {
    const nextDeliveryDate = new Date(deliveryDate);
    return `${nextDeliveryDate.getDate()} ${nextDeliveryDate.toLocaleString('default', {
      month: 'short',
    })} ${nextDeliveryDate.getFullYear()}`;
  };
  const userProfileLogo = profileLogo(projectConfiguration, account);
  useEffect(() => {
    setAccountData(true);
  }, []);
  const yearFirstTwoDigit = 20;
  useEffect(() => {
    const handleGetSubscription = async () => {
      const data = await getSubscriptionTemplateOrders();
      if (data?.errorCode !== 500 && data.length > 0) {
        // Get today's date
        const today = new Date();
        // Filter out canceled items
        const nonCancelledData = data.filter((item) => item?.orderState !== 'Cancelled');
        // Sort subscriptions by nextDeliveryDate
        nonCancelledData.sort((a, b) => {
          const dateA = new Date(a.custom?.fields?.nextDeliveryDate);
          const dateB = new Date(b.custom?.fields?.nextDeliveryDate);
          return dateA.getTime() - dateB.getTime();
        });
        // Find the next delivery date
        let nextScheduledTime = null;
        for (let i = 0; i < nonCancelledData.length; i++) {
          const nextDeliveryDate = new Date(nonCancelledData[i].custom?.fields?.nextDeliveryDate);
          if (nextDeliveryDate > today) {
            nextScheduledTime = nextDeliveryDate;
            break;
          }
        }
        // Filter upcoming subscriptions based on the next delivery date
        const upcomingSubscriptions = nonCancelledData.filter((item) => {
          const nextDeliveryDate = new Date(item.custom?.fields?.nextDeliveryDate);
          return nextDeliveryDate.getTime() === nextScheduledTime?.getTime();
        });
        setOrderHistoryData(upcomingSubscriptions);
      } else {
        setSubscriptionError(true);
      }
    };
    handleGetSubscription();
    if (projectConfiguration?.cardTypeDisplayName) {
      try {
        setCardDisplayNames(JSON.parse(projectConfiguration?.cardTypeDisplayName));
      } catch (error) {
        console.error('Errorparsing projectConfig for cardTypeDisplayName:', error);
      }
    }
  }, []);

  useEffect(() => {
    const getCards = async () => {
      const milstarCards = getCreditCards?.response?.milstaraccount ?? [];
      const bankCards = getCreditCards?.response?.bankcardaccount ?? [];
      const mergedCards = [...milstarCards, ...bankCards];
      setAllCards(mergedCards);
      setMilStarCard(milstarCards[0]);
    };
    getCards();
  }, []);

  const defaultCard = allCards.find((data) => {
    return data?.preferredcard === 'Y';
  });

  function closeStoreModal() {
    setFindStoreModal(false);
  }

  const showStoreModal = () => {
    setFindStoreModal(true);
    setSearchInput('');
    const fetchChannelList = async () => {
      const channelList: any = await listingStores('');
      setStoresList(channelList);
    };
    loggedIn && fetchChannelList();
    setStoresList([]);
  };
  function updateChannelId(id) {
    setChannelId(id);
  }

  const [isTabletSize] = useMediaQuery(tablet);
  const router = useRouter();

  const MilitryStar = () => {
    if (!accountData) return null;
    return !account?.isMilStarCardHolder ? (
      <MilStarCardSection cardData={cardData} />
    ) : (
      <div
        aria-labelledby="militaryStartCardSection"
        className="mb-5 h-[217px]  rounded border border-[#DFDFDF] py-3 px-5 "
      >
        <h3 id="militaryStartCardSection" className="mb-2 font-bold leading-[19.2px]">
          {formatAccountMessage({ id: 'militaryStarCardHolders', defaultMessage: 'Military Star Cardholders' })}
        </h3>
        <div className="mb-4 grid grid-cols-6 gap-4">
          <div className="col-span-1">{RenderCardsImages(milStarCard?.cardtype)}</div>
          <div className="col-span-5 text-sm">
            <p className="mb-2 leading-[16.8px]">
              {cardDisplayNames[milStarCard?.cardtype]} ending {milStarCard?.token?.slice(-4)}
            </p>
            <p className="mb-2 leading-[16.8px]">
              {formatAccountMessage({ id: 'retail', defaultMessage: 'Retail' })}:{' '}
              <strong>${milStarCard?.milstarbalance}</strong>{' '}
              {formatAccountMessage({ id: 'availableCredit', defaultMessage: 'available credit' })}
            </p>
            <p className="mb-2 leading-[16.8px]">
              {formatAccountMessage({ id: 'milCloth', defaultMessage: 'MilCloth' })}:{' '}
              <strong>${milStarCard?.militaryclothingbalance}</strong>{' '}
              {formatAccountMessage({ id: 'availableCredit', defaultMessage: 'available credit' })}
            </p>
            <p className="leading-[16.8px]">
              {formatAccountMessage({ id: 'rewards', defaultMessage: 'Rewards' })}:{' '}
              <strong>{parseInt(milStarCard?.rewardpointbalance, 10)}</strong>{' '}
              {formatAccountMessage({ id: 'availablePoints', defaultMessage: 'available points' })}
            </p>
          </div>
        </div>
        <div className="text-sm">
          <a href={cardData?.headerCol2} target="_blank" rel="noreferrer">
            <span className="text-[#043C63]">{formatCommonMessage({ id: 'manage', defaultMessage: 'Manage' })}</span>
          </a>
        </div>
      </div>
    );
  };

  return (
    <>
      <div aria-labelledby="account-Section-Heading" className="flex justify-between">
        <h3 id="account-Section-Heading" className="mb-[11px] text-[21px] font-bold leading-6 text-gray-900 md:mb-0">
          {formatAccountMessage({ id: 'accountHome', defaultMessage: 'Account Home' })}
        </h3>
      </div>
      <div className="mt-2 text-gray-900 md:flex md:flex-wrap md:justify-between">
        <div
          aria-labelledby="userProfileLabel"
          className="mb-5 h-[217px] rounded border border-[#DFDFDF] py-3 px-5 md:mr-5 md:w-[48%] lg:w-[424px] xl:w-[447px]"
        >
          <Link href="/account#profile">
            <a>
              <h3 id="userProfileLabel" className="mb-9 w-auto cursor-pointer font-bold">
                Hi {account.firstName}
              </h3>
            </a>
          </Link>
          <div className="mb-[29px] grid grid-cols-4 gap-4">
            <div className="col-span-1 ">
              <img
                src={userProfileLogo}
                id="profileImage"
                height={75}
                width={71}
                alt={account?.branchOfServiceDescription}
                className="mt-2"
              />
              <span id="profileImageLabel" className="sr-only">
                {account?.branchOfServiceDescription} Profile Image
              </span>
            </div>
            <div className="col-span-3 text-sm leading-7">
              <p className="font-bold">
                {account?.firstName} {account?.lastName}
              </p>
              <p>
                <span className="inline-block align-middle">
                  <MdOutlineEmail />
                </span>{' '}
                {account?.email}
              </p>
              <p>
                <span className="inline-block align-middle">
                  <MdOutlinePhone />
                </span>{' '}
                {account?.profilePhone || 'N/A'}
              </p>
            </div>
          </div>
          <div className="text-sm">
            <button
              id="viewProfileButton"
              aria-labelledby="viewProfileButtonLabel"
              className="mr-4 cursor-pointer text-[#043C63] hover:underline"
              onClick={() => router.push('/account#profile')}
            >
              {formatCommonMessage({ id: 'accountHome', defaultMessage: 'View Profile' })}
            </button>
            <button
              id="changePasswordButton"
              aria-labelledby="changePasswordButtonLabel"
              className="cursor-pointer text-[#043C63] hover:underline"
              onClick={() => router.push('/account#profile')}
            >
              {formatCommonMessage({ id: 'changePassword', defaultMessage: 'Change Password' })}
            </button>
          </div>
        </div>
        {isTabletSize ? (
          <div className=" md:w-[48%] lg:w-[424px] xl:w-[447px]">
            <MilitryStar />
          </div>
        ) : null}

        <div
          aria-labelledby="default-Address-Section"
          className="mb-5 h-[217px]  rounded border border-[#DFDFDF] py-3 px-5 md:mr-5  md:w-[48%] lg:w-[424px] xl:w-[447px]"
        >
          <h3 id="default-Address-Section" className="mb-8 font-bold">
            <Link href="/account#addresses">
              {formatCommonMessage({ id: 'defaultAddress', defaultMessage: 'Default Address' })}
            </Link>
          </h3>
          <div className="mb-5 grid grid-cols-6 gap-4">
            <div className="col-span-6 text-sm leading-6">
              {addresses?.length > 0 &&
                addresses?.map((address) => {
                  if (address?.isDefaultBillingAddress) {
                    return (
                      <>
                        <p className="mb-2">
                          {address?.firstName} {address?.lastName}
                        </p>
                        <p key={address?.addressId} className="mb-2">
                          {address?.streetNumber} {address?.streetName}
                          <br />
                          {address?.city}, {address?.state} {address?.postalCode}
                        </p>
                      </>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
          <div className="text-sm">
            <button
              id="viewAllAddressButton"
              className="cursor-pointer text-[#043C63] hover:underline"
              onClick={() => router.push('/account#addresses')}
            >
              {formatCommonMessage({ id: 'viewAddress', defaultMessage: 'View All Addresses' })}
            </button>
          </div>
        </div>

        <div
          aria-labelledby="default-Payment-Heading"
          className="mb-5 rounded border border-[#DFDFDF] py-3 px-5  md:w-[48%] lg:w-[424px] xl:h-[217px] xl:w-[447px]"
        >
          <h3 id="default-Payment-Heading" className="mb-8 font-bold" tabIndex={1}>
            <Link href={'/account#wallet'}>
              {formatCommonMessage({ id: ' defaultPayment', defaultMessage: ' Default Payment' })}
            </Link>
          </h3>
          {defaultCard && (
            <div className="mb-4 ">
              <div className="flex items-center">
                <div className="mt-2">{RenderCardsImages(defaultCard?.cardtype)}</div>
                <div className=" mt-2 ml-[7.48px] text-sm ">
                  <p>
                    {cardDisplayNames[defaultCard?.cardtype]} ending {defaultCard?.token?.slice(-4)}
                  </p>
                </div>
              </div>
              <div className="mt-3 mb-[18px] text-sm">
                <p>
                  <section className="mb-2 flex items-center">
                    {defaultCard?.isExpired ? (
                      <section className="flex items-center">
                        {' '}
                        <WarningIcon className="mr-2" />
                        <p className="text-red-900">
                          {formatAccountMessage({ id: 'Expired', defaultMessage: 'Expired' })}
                        </p>{' '}
                      </section>
                    ) : (
                      <p className="text-[#333]">
                        {formatAccountMessage({ id: 'Expires', defaultMessage: 'Expires' })}
                      </p>
                    )}
                  </section>
                  {CalculateExpDate(defaultCard?.expirydate)}
                </p>
              </div>
            </div>
          )}
          <div className="text-sm">
            <button
              id="viewCreditCardButton"
              className="cursor-pointer text-[#043C63] hover:underline"
              onClick={() => router.push('/account#wallet')}
            >
              {formatAccountMessage({ id: 'viewCreditCard', defaultMessage: 'View All Credit Cards' })}
            </button>
          </div>
        </div>

        {!isVeteranAccount(account) && (
          <div
            aria-labelledby="default-Store-Section"
            className="mb-5 rounded border border-[#DFDFDF] py-3 px-5 md:mr-5 md:w-[48%] lg:w-[424px] xl:h-[217px] xl:w-[447px]"
          >
            <h3 id="default-Store-Section" className="mb-6 font-bold">
              {formatCommonMessage({ id: 'defaultStore', defaultMessage: 'Default Store' })}
            </h3>
            <div className="mb-4 grid grid-cols-6 gap-4">
              <div className="col-span-6 text-sm leading-6">
                <p className="mb-2">{account?.defaultStore?.name}</p>
                <p className="leading-5">
                  {account?.defaultStore?.address?.streetName} {account?.defaultStore?.address?.city}
                  <br />
                  {account?.defaultStore?.address?.state} {account?.defaultStore?.address?.postalCode}
                  <br />
                  {account?.defaultStore?.address?.country}
                </p>
              </div>
            </div>
            <div className="text-sm">
              <button
                onClick={() => showStoreModal()}
                className="my-3 cursor-pointer  text-affes-sm text-[#043C63] hover:underline "
              >
                {formatCommonMessage({ id: 'changeStore', defaultMessage: 'Change Store' })}{' '}
              </button>
            </div>
          </div>
        )}

        {orderHistoryData?.map((subTemplate: any, i: number) => (
          <div
            className="mb-3 rounded border border-[#DFDFDF] py-3 px-5  md:w-[48%] lg:w-[424px] xl:h-[217px] xl:w-[447px]"
            key={i}
            aria-labelledby="order-History"
          >
            <h3 id="order-History" className="mb-3 font-bold">
              <Link href="/account#subscription">
                {formatCommonMessage({ id: 'upcomingSubscriptions', defaultMessage: 'Upcoming Subscriptions' })}
              </Link>
            </h3>
            <p className="mb-[18px]">
              {formatCommonMessage({ id: 'nextDelivery', defaultMessage: 'Next Delivery' })}
              <strong>{formatNextDeliveryDate(subTemplate.custom?.fields?.nextDeliveryDate)}</strong>
            </p>

            {subTemplate?.lineItems?.[0]?.isSubscription ? (
              <div key={i} className="mb-2 flex items-center justify-around">
                <div className="">
                  <img
                    src={subTemplate?.lineItems?.[0]?.variant?.images[0]}
                    alt="Product Image"
                    className=" h-[70px] w-[70px]"
                  />
                </div>
                <section className=" w-[235px] text-sm">
                  <Link href={subTemplate?.lineItems?.[0]?._url}>
                    <p className="cursor-pointer text-sm font-bold text-[rgba(4,60,99,1)]">
                      {subTemplate?.lineItems?.[0].name}
                    </p>
                  </Link>
                  <p className="mt-[6px]  text-sm text-[rgba(102,102,102,1)] ">
                    {formatCommonMessage({ id: 'items', defaultMessage: 'Item' })}
                    #: {subTemplate?.lineItems?.[0]?.variant?.sku}
                  </p>
                </section>
              </div>
            ) : (
              <p className="text-sm text-[#043C63]">
                {formatCommonMessage({ id: 'nosubscriptions', defaultMessage: 'No Matching Orders' })}
              </p>
            )}

            <div className="text-sm">
              <button
                id="manageSubscription"
                className="my-3 cursor-pointer text-[#043C63] hover:underline"
                onClick={() => router.push('/account#subscription')}
              >
                {formatCommonMessage({ id: 'manageSubscriptions', defaultMessage: 'Manage Subscriptions' })}
              </button>
            </div>
          </div>
        ))}
        {subscriptionError && (
          <div
            aria-labelledby="upcoming-Subscriptions-Section"
            className="mb-3 rounded border border-[#DFDFDF] py-3 px-5  md:w-[48%] lg:w-[424px] xl:h-[217px] xl:w-[447px]"
          >
            <h3 id="upcoming-Subscriptions-Section" className="mb-3 font-bold">
              <Link href="/account#subscription">
                {formatCommonMessage({ id: 'upcomingSubscriptions', defaultMessage: 'Upcoming Subscriptions' })}
              </Link>
            </h3>
            <p className="my-5 text-sm">
              {formatCommonMessage({ id: 'nosubscriptions', defaultMessage: 'No Matching Orders' })}
            </p>
          </div>
        )}

        {!isTabletSize ? (
          <div className=" md:w-[48%] lg:w-[424px] xl:w-[447px]">
            <MilitryStar />
          </div>
        ) : null}
      </div>
      <FindStore
        findStoreModal={findStoreModal}
        closeModal={closeStoreModal}
        setLoginModal={setLoginModal}
        setFindStoreModal={setFindStoreModal}
        channelId={channelId}
        updateChannelId={updateChannelId}
        setStoresList={setStoresList}
        storeList={storeList}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setResetDefaultStore={false}
      />
    </>
  );
};

export default AccountContent;
