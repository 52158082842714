import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GrClose } from 'react-icons/gr';
import {
  MdNavigateNext,
  MdOutlineArrowBackIosNew,
  MdOutlineGridView,
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';
import { useAccount } from 'frontastic';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import FindStore from '../Modals/findStore';
const LoginModal = dynamic(() => import('../Modals/Login/login'));
import MilitryStarMobile from './militaryStarMobile';
const MobileAccordion = ({
  closeMenu,
  menuCategories,
  leftColumn,
  rightColumn,
  findStore,
  militaryStarData,
  militaryStarApply,
  militaryStarParentURL,
  weeklyAd,
  setStoresList,
  storeList,
  searchInput,
  setSearchInput,
  setResetDefaultStore,
  megaMenuAssetpathObject,
  templates,
}) => {
  const router = useRouter();
  const [showNextLevel, setShowNextLevel] = useState(null);
  const [showParent, setShowParent] = useState(true);
  const [showChild, setShowChild] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [toggleAccordion, setToggleAccordion] = useState(false);
  const storeIcon = findStore?.mIcon?.media?.file;
  const storeLabel = findStore?.header;
  const [channelId, setChannelId] = useState(null);
  const toggleItem = (itemId) => {
    if (itemId === activeItem) {
      setActiveItem(null);
    } else {
      setActiveItem(itemId);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { formatMessage } = useFormat({ name: 'common' });
  const [loginModal, setLoginModal] = useState(false);
  const [findStoreModal, setFindStoreModal] = useState(false);
  const { loggedIn } = useAccount();
  const [redirectUrl, setRedirectUrl] = useState(' ');
  function showStoreModal() {
    setFindStoreModal(true);
  }

  function closeStoreModal() {
    setFindStoreModal(false);
  }

  function showLogin() {
    setLoginModal(true);
  }

  function closeLoginModal() {
    setLoginModal(false);
  }
  function updateChannelId(id) {
    setChannelId(id);
  }
  function handlePageScroll(property) {
    document.body.style.overflowY = property;
  }
  function handleClose() {
    closeMenu(false);
    handlePageScroll('visible');
  }

  useEffect(() => {
    const handleRouteChange = () => {
      handleClose();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);
  const getMatchedContent = (showNextLevel, cmsContentArray, templates) => {
    const cmsAssetPath = cmsContentArray[showNextLevel?.categoryName];

    if (!cmsAssetPath) {
      console.error(`No asset path found for category: ${showNextLevel?.categoryName}`);
      return null;
    }

    const matchedTemplate = templates?.find((template) => template?.assetPath === cmsAssetPath);

    if (matchedTemplate) {
      return matchedTemplate?.content;
    } else {
      return null;
    }
  };
  return (
    <>
      {showParent && (
        <section className="scrollbar-hide fixed left-0 top-0 z-20 h-full w-11/12 overflow-y-auto bg-white">
          <section>
            <h4 className="flex items-center py-2 px-4 text-sm font-bold text-blue-900">
              <MdOutlineGridView className="mr-3 text-xl text-gray-600" />
              {formatMessage({ id: 'shopByCategory', defaultMessage: 'Shop by category' })}
            </h4>
            <button type="button" className="absolute right-5 top-4 cursor-pointer" onClick={handleClose}>
              <GrClose />
            </button>
          </section>
          {menuCategories?.map((item, index) => (
            <section key={index} className="w-full">
              <ul className="mt-2">
                <li
                  key={index}
                  className={`flex w-full justify-between border-t border-gray-300 py-2 px-4 ${
                    activeItem === index ? 'bg-gray-100 font-bold' : ''
                  }`}
                >
                  <section className="relative w-full text-left text-sm font-normal text-gray-900">
                    <Link href={item?.url}>{item?.categoryName}</Link>
                    <button
                      onClick={() => {
                        setShowNextLevel(item);
                        setShowParent(false);
                        setShowChild(true);
                      }}
                    >
                      <MdNavigateNext className="absolute right-0 top-1 text-xl text-gray-900" />
                    </button>
                  </section>
                </li>
              </ul>
            </section>
          ))}
          <section className="mt-3 bg-[#EEEEEE]">
            {leftColumn.map((Item, child) => (
              <section key={child} className="py-3 px-4">
                <section className="mr-14 font-medium text-gray-900">
                  <Link href={Item.links}>
                    <a className="flex items-center text-sm">
                      <span className="mr-3 w-8">
                        <img src={Item?.mIcon?.media?.file} alt={`${Item.header} Icon`} />
                      </span>{' '}
                      {Item.header}
                    </a>
                  </Link>
                </section>
              </section>
            ))}
            <section className="py-3 px-4">
              {weeklyAd?.loginRequired ? (
                loggedIn ? (
                  <Link href={weeklyAd?.links}>
                    <a className=" flex cursor-pointer items-center text-sm  text-gray-900">
                      <span className="mr-3 w-8">
                        {' '}
                        <img src={weeklyAd?.mIcon?.media?.file} alt="Weekly Ad Icon" className="mr-3" />
                      </span>
                      {''}
                      {weeklyAd?.header}
                    </a>
                  </Link>
                ) : (
                  <button
                    onClick={() => {
                      showLogin();
                      setRedirectUrl(weeklyAd?.links);
                    }}
                    className="mr-14 flex items-center text-affes-sm font-medium text-gray-900"
                  >
                    <span className="mr-3 w-8">
                      {' '}
                      <img src={weeklyAd?.mIcon?.media?.file} alt="Weekly Ad Icon" className="mr-3" />
                    </span>
                    {''}
                    {weeklyAd?.header}
                  </button>
                )
              ) : (
                <Link href={weeklyAd?.links}>
                  <a className=" flex cursor-pointer items-center text-sm  text-gray-900">
                    <span className="mr-3 w-8">
                      {' '}
                      <img src={weeklyAd?.mIcon?.media?.file} alt="Weekly Ad Icon" className="mr-3" />
                    </span>
                    {''}
                    {weeklyAd?.header}
                  </a>
                </Link>
              )}
            </section>

            {rightColumn.map((Item, subChild) => (
              <section key={subChild} className="py-3 px-4">
                <section className="mr-14 font-medium text-gray-900 lg:mb-0 ">
                  {Item?.loginRequired ? (
                    loggedIn ? (
                      <Link href={Item.links}>
                        <a className="flex items-center text-sm">
                          <span className="mr-3 w-8">
                            <img src={Item?.mIcon?.media?.file} alt={`${Item.header} Icon`} />
                          </span>{' '}
                          {Item.header}
                        </a>
                      </Link>
                    ) : (
                      <button
                        onClick={() => {
                          showLogin();
                          setRedirectUrl(Item.links);
                        }}
                        className="mr-14 flex items-center text-affes-sm font-medium text-gray-900"
                      >
                        <span className="mr-3 w-8">
                          {' '}
                          <img src={Item?.mIcon?.media?.file} alt={`${Item.header} Icon`} className="mr-3" />
                        </span>
                        {''}
                        {Item?.header}
                      </button>
                    )
                  ) : (
                    <Link href={Item.links}>
                      <a className="flex items-center text-sm">
                        <span className="mr-3 w-8">
                          <img src={Item?.mIcon?.media?.file} alt={`${Item.header} Icon`} />
                        </span>{' '}
                        {Item.header}
                      </a>
                    </Link>
                  )}
                </section>
              </section>
            ))}

            <section className="order-5 py-3 px-4 md:hidden">
              <button onClick={showStoreModal} className="flex cursor-pointer  items-center text-sm">
                <span className="mr-3 w-8">
                  <img src={storeIcon} alt="Find Store Icon" className="mr-3" />
                </span>{' '}
                <p className="text-gray-900">{storeLabel}</p>
              </button>
            </section>
            <section className="py-3 px-4">
              <MilitryStarMobile
                militaryStarData={militaryStarData}
                militaryStarApply={militaryStarApply}
                militaryStarParentURL={militaryStarParentURL}
              />
            </section>
          </section>
        </section>
      )}
      {showChild && (
        <section className="fixed left-0 top-0 z-20 h-full w-11/12 overflow-y-auto bg-white">
          <section className="w-full">
            <section>
              <button
                type="button"
                className="-ml-1 flex w-full items-center border-b border-gray-300 py-2 px-4 text-base font-bold uppercase text-gray-900"
                onClick={() => {
                  setShowParent(true);
                  setShowChild(false);
                }}
              >
                <MdOutlineArrowBackIosNew className="mr-1" /> {showNextLevel?.categoryName}
              </button>
              <button type="button" className="absolute right-5 top-4 cursor-pointer" onClick={handleClose}>
                <GrClose />
              </button>
            </section>
            {showNextLevel?.children?.map((subItem: any, child) => (
              <section key={child}>
                <section>
                  <button
                    key={child}
                    className={`flex w-full items-center justify-between border-b border-gray-300 py-2 px-4 text-left  text-blue-900 ${
                      activeItem === child ? 'bg-gray-100 font-bold' : ''
                    }`}
                  >
                    {subItem?.children?.length > 0 ? (
                      <>
                        <p
                          className={` text-sm ${
                            toggleAccordion && activeItem === subItem
                              ? 'font-bold text-[#043C63]'
                              : ' font-normal text-[#333333]'
                          }`}
                        >
                          <Link href={subItem?.url}>{subItem?.categoryName}</Link>
                        </p>
                        <button
                          type="button"
                          onClick={() => {
                            toggleItem(subItem);
                            setActiveItem(subItem);
                            setToggleAccordion(!toggleAccordion);
                          }}
                          className="text-xl"
                        >
                          {toggleAccordion && activeItem === subItem ? (
                            <MdOutlineKeyboardArrowDown className="text-3xl text-[#333333]" />
                          ) : (
                            <MdOutlineKeyboardArrowUp className="text-3xl text-[#333333]" />
                          )}
                        </button>
                      </>
                    ) : (
                      <p
                        className={`text-sm ${
                          toggleAccordion && activeItem === subItem ? 'text-[#043C63]' : 'text-[#333333]'
                        }`}
                      >
                        <Link href={subItem?.url}>{subItem?.categoryName}</Link>
                      </p>
                    )}
                  </button>
                  {activeItem?.children?.length > 0 && toggleAccordion && (
                    <section>
                      {activeItem === subItem && (
                        <ul>
                          {activeItem?.children?.map((childItem, childIndex) => (
                            <li
                              className="border-b border-gray-300 px-8 py-2 text-sm font-normal text-gray-900"
                              key={childIndex}
                            >
                              <Link href={childItem.url}>{childItem.categoryName}</Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </section>
                  )}
                </section>
              </section>
            ))}
            <section className={`py-2 px-4`}>
              <Link href={`/brands?N=${showNextLevel?.categoryId}`}>
                <a className="mb-3 cursor-pointer text-affes-sm font-semibold text-blue-900">
                  {formatMessage({ id: 'shopAllBrands', defaultMessage: 'Shop All Brands' })}
                </a>
              </Link>
              <section>
                <div
                  dangerouslySetInnerHTML={{
                    __html: getMatchedContent(showNextLevel, megaMenuAssetpathObject, templates),
                  }}
                />
              </section>
            </section>
          </section>
        </section>
      )}
      <LoginModal loginModal={loginModal} closeModal={closeLoginModal} loginCallBack={() => router.push(redirectUrl)} />
      <FindStore
        findStoreModal={findStoreModal}
        closeModal={closeStoreModal}
        setLoginModal={setLoginModal}
        setFindStoreModal={setFindStoreModal}
        channelId={channelId}
        updateChannelId={updateChannelId}
        setStoresList={setStoresList}
        storeList={storeList}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        setResetDefaultStore={setResetDefaultStore}
      />
    </>
  );
};

export default MobileAccordion;
