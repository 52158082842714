import useSWR, { mutate } from 'swr';
import { Address } from '@Types/account/Address';
import { Cart } from '@Types/cart/Cart';
import { Discount } from '@Types/cart/Discount';
import { Variant } from '@Types/product/Variant';
import { fetchApiHub, revalidateOptions } from 'frontastic';
import { LineItem } from '@Types/cart/LineItem';
import { number, string } from 'yup';
import { GuestCheckoutRequestBody } from '@Types/request/GuestCheckoutRequestBody';
import { LineItemShippingDetails } from '@Types/cart/LineItemShippingDetails';
const jsonChecksum = require('json-checksum');

export type CartDetails = {
  account?: { email: string };
  shipping?: Address;
  billing?: Address;
};
export const orderConfirmation = async (orderId: string, isAccountCheck: boolean) => {
  const payload = {
    orderId: orderId,
    isAccountCheck: isAccountCheck,
  };
  const res = await fetchApiHub(
    '/action/cart/getOrderById',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions);
};

export const getAllConfigs = () => {
  const { data, isValidating, error } = useSWR('/action/project/getAllConfigs', fetchApiHub, revalidateOptions);
  return { studioConfig: data, studioConfigError: error, studioConfigLoading: isValidating };
};

export const getCartItems = async () => {
  const res = await fetchApiHub('/action/cart/getCart');
  return res;
};
export const checkout = async (payload: {
  orderEmail: string;
  orderPhone: string;
  optIn: boolean;
  ioBlackBox: string;
  cvv: string;
}) => {
  const res = await fetchApiHub(
    '/action/cart/checkout',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart');
  return res;
};

export const orderHistory = async (timeframe?: string, pageSize?: number, page?: number) => {
  const payload = {
    timeframe: timeframe,
    pageSize: pageSize,
    page: page,
  };
  return await fetchApiHub(
    '/action/cart/getOrders',
    {
      method: 'POST',
    },
    payload,
  );
};

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings');
};

export const getShippingMethods = async () => {
  return await fetchApiHub('/action/cart/getShippingMethods');
};

export const getProjectConfiguration = async () => {
  // return window.localStorage.getItem('gac_cache');
  const checksum = window.localStorage.getItem('checksum');
  const gacCache = window.localStorage.getItem('gac_cache');
  const isChecksum = !!checksum;
  const isGacCache = !!gacCache;
  let projectConfigurationData = null;
  const projectConfigurationResponse = await fetchApiHub(
    `/action/project/getAllConfigs?checksumKey=${isChecksum && isGacCache ? checksum : undefined}`,
  );
  if (projectConfigurationResponse?.statusCode === 204) {
    projectConfigurationData = JSON.parse(gacCache);
  } else {
    window.localStorage.setItem('checksum', jsonChecksum(projectConfigurationResponse));
    window.localStorage.setItem('gac_cache', JSON.stringify(projectConfigurationResponse));
    projectConfigurationData = projectConfigurationResponse;
  }
  return projectConfigurationData;
};

export const getDonationProducts = async () => {
  return await fetchApiHub('/action/cart/getDonationProducts');
};

export const addItem = async (
  variant: Variant,
  quantity: number,
  channel?: string,
  personalizationTextLines?: string[],
  subscriptionFrequencySettings?: String,
  additionalSku?: any,
  isGuestUser?: boolean,
) => {
  const payload = {
    variant: {
      sku: variant.sku,
      count: quantity,
      channel: channel,
      additionalSku: additionalSku,
    },
    personalizationTextLines,
    subscriptionFrequencySettings,
    isGuestUser,
  };
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  } else {
    if (!res?.errorCode) {
      await mutate('/action/cart/getCart');
    }
  }
  return res;
};

export const removeItem = async (lineItemId: string, isGuestUser?: boolean) => {
  const payload = {
    lineItem: { id: lineItemId },
    isGuestUser,
  };

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  } else {
    await mutate('/action/cart/getCart');
  }
  return res;
};

export const removeMarketplaceItem = async (customLineItemId: string) => {
  const payload = {
    customLineItemId: customLineItemId,
  };

  const res = await fetchApiHub(
    '/action/marketplace/removeMarketplaceLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const updateItem = async (lineItemId: string, newQuantity: any, isGuestUser?: boolean) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: parseInt(newQuantity),
    },
    isGuestUser,
  };
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const moveToSaveForLater = async (lineItem: LineItem) => {
  const payload = {
    id: lineItem?.lineItemId,
    sku: lineItem?.variant?.sku,
    count: 1,
  };
  const res = await fetchApiHub(
    '/action/cart/moveToSaveForLater',
    {
      method: 'POST',
    },
    payload,
  );
  if (!res?.errorCode) {
    await mutate('/action/cart/getCart', res, { revalidate: false });
    await mutate('/action/wishlist/getSaveForLater');
  }

  return res;
};

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  };

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  };
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart', res, { revalidate: false });
};

export const markAsGift = async (payload) => {
  const res = await fetchApiHub(
    '/action/cart/updateGiftInformation',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};
export const updateShippingAddress = async (lineItemId: string, shipAddressId: string) => {
  const payload = {
    lineItemId: lineItemId,
    shipAddressId: shipAddressId,
  };
  const res = await fetchApiHub(
    '/action/cart/updateShippingAddress',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const updateShippingType = async (
  lineItemId: string,
  shippingType: string,
  shipLocation?: string,
  pickupLocation?: string,
) => {
  const payload = {
    lineItemId: lineItemId,
    shippingType: shippingType,
    shipLocation: shipLocation,
    pickupLocation: pickupLocation,
  };
  const res = await fetchApiHub(
    '/action/cart/updateShippingType',
    {
      method: 'POST',
    },
    payload,
  );
  await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const addAdditionalItem = async (
  variant: any,
  quantity: number,
  isMutable: boolean,
  personalizationTextLines: any,
) => {
  const payload = {
    variant: {
      sku: variant?.sku,
      count: quantity,
      additionalSku: variant?.additionalSku,
    },
    parentLineItemId: variant?.parentLineItemId,
    personalizationTextLines: personalizationTextLines,
  };
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  );
  if (!isMutable && res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }

  return res;
};

export const removeAdditionalItem = async (lineItemId: string, parentLineItemId: string, isMutable: boolean) => {
  const payload = {
    lineItem: {
      id: lineItemId,
    },
    parentLineItemId: parentLineItemId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeAdditionalService',
    {
      method: 'POST',
    },
    payload,
  );
  if (isMutable && res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};
export const removeVAS = async (parentLineItemId: string) => {
  const payload = {
    parentLineItemId: parentLineItemId,
  };
  const res = await fetchApiHub(
    '/action/cart/removeAdditionalService',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const getShippingForLineItem = async (lineItemId: string) => {
  const payload = {
    lineItem: {
      id: lineItemId,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/getShippingForLineItem',
    {
      method: 'POST',
    },
    payload,
  );

  return res;
};

export const updateLineItemShippingDetails = async (payload: LineItemShippingDetails) => {
  const res = await fetchApiHub(
    '/action/cart/updateLineItemShippingDetails',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }
  return res;
};

export const updateMarketplaceLineItemShippingDetails = async (payload: LineItemShippingDetails) => {
  const res = await fetchApiHub(
    '/action/cart/updateCustomLineItemShippingDetails',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }
  return res;
};

export const addSubscriptionService = async (lineItemId: string, subscriptionFrequency: string) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      subscriptionFrequencySettings: subscriptionFrequency,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/addSubscription',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
};

export const removeSubscriptionService = async (lineItemId: string) => {
  const payload = {
    lineItem: {
      id: lineItemId,
    },
  };
  const res = await fetchApiHub(
    '/action/cart/removeSubscription',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
};

export const checkGiftCardBalance = async (giftCardNumber: string, giftCardPin: string) => {
  const payload = {
    giftCardNumber,
    giftCardPin,
  };
  const res = await fetchApiHub(
    '/action/cart/checkGiftCard',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};

export const addGiftCardPayment = async (giftCardNumber: string, giftCardPin: string) => {
  const payload = {
    giftCardNumber,
    giftCardPin,
    paymentMethod: 'giftCard',
  };
  const res = await fetchApiHub(
    '/action/cart/addPayment',
    {
      method: 'POST',
    },
    payload,
  );
  if (!res.errorCode) {
    await reCalculatePayments();
  }
  return res;
};

export const removePayment = async (paymentId: string, mutateGetCart: boolean = false) => {
  const payload = {
    paymentId,
  };
  const res = await fetchApiHub(
    '/action/cart/removePayment',
    {
      method: 'POST',
    },
    payload,
  );
  if (mutateGetCart && res?.cartId) {
    mutate('/action/cart/getCart', res, { revalidate: false });
  }
  return res;
};

export const setBillingAddress = async (payload) => {
  const res = await fetchApiHub(
    '/action/cart/setBillingAddress',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart');
  return res;
};

export const addNewCreditCard = async (payload) => {
  const res = await fetchApiHub(
    '/action/ews/addCreditCard',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart');
  return res;
};

export const getNewCreditCard = async (): Promise<any> => {
  const res = await fetchApiHub('/action/ews/getCreditCards', { method: 'POST' });

  return res;
};

export const addCreditCardForPayment = async (payload) => {
  const res = await fetchApiHub(
    '/action/cart/addPayment',
    {
      method: 'POST',
    },
    payload,
  );
  if (!res.errorCode) {
    await reCalculatePayments();
  }
  return res;
};
export const pickUpPersonInformation = async (lineItem: any) => {
  const payload = {
    lineItem,
  };
  const res = await fetchApiHub(
    '/action/cart/updatePickUpPersonInformation',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const addEGiftCardInformation = async (lineItem: any, isGuestUser: boolean) => {
  const payload = {
    lineItem,
    isGuestUser,
  };
  const res = await fetchApiHub(
    '/action/cart/setEGiftCardInformation',
    {
      method: 'POST',
    },
    payload,
  );
  if (res?.cartId) await mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const addBundle = async (payload: any, { skipMutate = false } = {}) => {
  const res = await fetchApiHub(
    '/action/cart/addBundle',
    {
      method: 'POST',
    },
    payload,
  );
  !skipMutate && res?.cartId && (await mutate('/action/cart/getCart', res, { revalidate: false }));
  return res;
};

export const removeBundle = async (lineItemId: string, { skipMutate = false } = {}) => {
  const res = await fetchApiHub(
    '/action/cart/removeBundle',
    {
      method: 'POST',
    },
    {
      lineItemId: lineItemId,
    },
  );
  !skipMutate && res?.cartId && (await mutate('/action/cart/getCart', res, { revalidate: false }));
  return res;
};

export const updateBundle = async (payload: any, lineItemId: string) => {
  const addBundleResponse = await addBundle(payload, { skipMutate: true });
  if (addBundleResponse?.cartId) {
    const rmBundleRes = await removeBundle(lineItemId, { skipMutate: true });
    if (rmBundleRes?.cartId) {
      mutate('/action/cart/getCart', rmBundleRes, { revalidate: false });
    } else {
      await mutate('/action/cart/getCart');
    }
    return rmBundleRes;
  }
  return addBundleResponse;
};

export const updateBundleQuantity = async (lineItemId: string, count: number) => {
  const res = await fetchApiHub(
    '/action/cart/updateBundleQuantity',
    {
      method: 'POST',
    },
    {
      lineItemId: lineItemId,
      count: count,
    },
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const updateBundleShippingAddress = async (
  lineItemId: string,
  shippingAddress: Address,
  shippingMethodId: string,
) => {
  const res = await fetchApiHub(
    '/action/cart/updateBundleShippingAddress',
    {
      method: 'POST',
    },
    {
      lineItemId,
      shippingAddress,
      shippingMethodId,
    },
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const cancelOrder = async (orderId?: string, isCancelled?: boolean) => {
  const payload = {
    orderId: orderId,
    isCancelled: isCancelled,
  };
  const res = await fetchApiHub(
    '/action/cart/cancelOrder',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getOrders');
  return res;
};

export const guestCheckout = async (payload: GuestCheckoutRequestBody) => {
  const res = await fetchApiHub(
    '/action/cart/guestCheckout',
    {
      method: 'POST',
    },
    payload,
  );
  mutate('/action/cart/getCart');
  return res;
};

export const updateGuestShippingAddress = async (
  lineItemId: string,
  shippingAddress: Address,
  shippingMethodId: string,
) => {
  const res = await fetchApiHub(
    '/action/cart/updateGuestShippingAddress',
    {
      method: 'POST',
    },
    {
      lineItemId,
      shippingAddress,
      shippingMethodId,
    },
  );
  if (res?.cartId) mutate('/action/cart/getCart', res, { revalidate: false });
  return res;
};

export const returnClaimForm = async (
  payload = {
    orderId: string,
    orderLineId: string,
    itemId: string,
    returnReason: string,
    returnNotes: string,
    attachmentContent: [],
  },
) => {
  const res = await fetchApiHub('/action/cart/returnClaimForm', { method: 'POST' }, payload);
  return res;
};

export const returnEmailInstructions = async () => {
  const response = await fetchApiHub('/action/cart/returnsInstructions', { method: 'GET' });
  return response;
};
export const reCalculatePayments = async () => {
  const res = await fetchApiHub('/action/cart/recalculatePayments', {
    method: 'POST',
  });
  await mutate('/action/cart/getCart');
  return res;
};

export const proceedToCheckout = async (isGuestUser: boolean) => {
  const queryParams = new URLSearchParams({
    isGuestUser: isGuestUser.toString(),
  });

  const queryURL = `/action/cart/proceedToCheckout?${queryParams}`;

  const res = await fetchApiHub(queryURL);
  return res;
};

export const getStorePrice = async (sku: string, storeId: string) => {
  const res = await fetchApiHub(`/action/cart/getStorePrice?sku=${sku}&storeId=${storeId}`);
  mutate('/action/cart/getCart');
  return res;
};

export const getCartInventory = async () => {
  const res = await fetchApiHub('/action/cart/getInventory', { method: 'GET' });
  return res;
};

export const validateInventory = async () => {
  const res = await fetchApiHub('/action/cart/validateInventory', { method: 'GET' });
  return res;
};

export const fetchDefaultPayment = async () => {
  const res = await fetchApiHub('/action/cart/setDefaultPayment', {
    method: 'POST',
  });
  // await mutate('/action/cart/getCart');
  return res;
};

export const getOrderByCartId = async (cartId: any) => {
  const payload = {
    cartId,
  };
  const res = await fetchApiHub(
    '/action/cart/getOrderByCartId',
    {
      method: 'POST',
    },
    payload,
  );
  return res;
};
