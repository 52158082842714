import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import ReactPaginate from 'react-paginate';
import { GA_FIND_STORE } from 'helpers/constants/aafes';
import useGATrackUserAction from 'helpers/hooks/gaEvents/useGATrackUserAction';
import { useFormat } from 'helpers/hooks/useFormat';
import { useAccount } from 'frontastic';
import StoreNameURL from '../../../../helpers/utils/storeNameURL';
import StarIcon from '../../../icons/star-icon-blue';

const Store = ({ storeList, itemsPerPage, setLoginModal, setFindStoreModal, channelId, updateChannelId }) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'common' });
  const { loggedIn, defaultStoreAddress } = useAccount();
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const [currentItems, setCurrentItems] = useState(
    storeList?.length > 0 ? storeList?.slice(itemOffset, endOffset) : [],
  );
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(Math.ceil(storeList?.length / itemsPerPage));
  const [defaultStore, setDefaultStore] = useState(null);
  const [notDefaultStores, setNotDefaultStores] = useState([]);
  const [isStoresFetched, setIsStoresFetched] = useState(false);
  const { trackSelectItem } = useGATrackUserAction();
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % storeList.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };
  const makeMyStore = (e, channelKey) => {
    e.preventDefault();
    defaultStoreAddress(channelKey);
    setFindStoreModal(false);
  };

  function showLogin(channelKey) {
    setFindStoreModal(false);
    setLoginModal(true);
    updateChannelId(channelKey);
  }

  useEffect(() => {
    if (notDefaultStores?.length > 0) {
      const endOffset = itemOffset + itemsPerPage;
      defaultStore !== null && setIsStoresFetched(true);
      setCurrentItems(notDefaultStores?.length > 0 ? notDefaultStores?.slice(itemOffset, endOffset) : []);
    }
  }, [notDefaultStores, defaultStore, itemOffset]);

  useEffect(() => {
    setItemOffset(0);
    setCurrentPage(0);
    setNotDefaultStores(storeList?.errorCode === 401 ? [] : storeList?.slice(1));
    setDefaultStore(storeList?.[0]);
    setPageCount(Math.ceil(storeList?.length / itemsPerPage));
  }, [storeList]);

  const handleFindStoreGTMEvent = async () => {
    trackSelectItem({
      contentId: GA_FIND_STORE,
    });
  };

  return (
    <>
      {storeList?.length > 0 && isStoresFetched && (
        <>
          <section
            className={
              loggedIn && 'border-[rgba(0,0,0, 0.2)] mb-4 rounded border bg-[#F4F4F4] pl-5 pr-[13px] pt-4 pb-5'
            }
          >
            {loggedIn && (
              <p className="mb-[9px] flex items-center text-affes-sm leading-5 text-gray-800">
                <StarIcon className="mr-[5.21px]" /> {formatMessage({ id: 'myStore', defaultMessage: 'My Store' })}
              </p>
            )}
            <section className="flex justify-between">
              <section>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleFindStoreGTMEvent();
                    setFindStoreModal(false);
                    const storeLocatorUrl = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                      defaultStore?.name,
                    )}`;
                    router.push(storeLocatorUrl);
                  }}
                  onKeyDown={(ev) => {
                    if (ev.key === 'Enter') {
                      setFindStoreModal(false);
                      const storeLocatorUrl = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                        defaultStore?.name,
                      )}`;
                      router.push(storeLocatorUrl);
                    }
                  }}
                  className="mb-2 cursor-pointer text-affes-sm font-bold leading-5 text-blue-900"
                >
                  {defaultStore?.name}
                </button>

                <p className="mb-2 text-affes-sm font-semibold text-gray-900">
                  {defaultStore?.address?.streetName} {defaultStore?.address?.city}
                  <br />
                  {defaultStore?.address?.state} {defaultStore?.address?.postalCode}
                </p>

                <section className="md:flex md:w-[22rem]">
                  <a href={`tel:${defaultStore?.phoneNumber}`}>
                    <p
                      className="mr-4 cursor-pointer text-affes-sm leading-5 text-blue-900"
                      onClick={handleFindStoreGTMEvent}
                    >
                      {defaultStore?.phoneNumber}
                    </p>
                  </a>
                  <button
                    onClick={async () => {
                      await handleFindStoreGTMEvent();
                      setFindStoreModal(false);
                      if (window) {
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${defaultStore?.lat},${defaultStore.long}`,
                          '_blank',
                        );
                      }
                    }}
                    className="mr-6 cursor-pointer text-affes-sm leading-5 text-blue-900"
                  >
                    {formatMessage({ id: 'getDirections', defaultMessage: 'Get Directions' })}
                  </button>
                  {!loggedIn && defaultStore?.isClickToBrick && (
                    <button
                      onClick={() => {
                        handleFindStoreGTMEvent();
                        showLogin(defaultStore?.key);
                      }}
                      type="button"
                      className="cursor-pointer text-affes-sm leading-5 text-blue-900"
                    >
                      {formatMessage({ id: 'makeMyStore', defaultMessage: 'Make This My Store' })}
                    </button>
                  )}
                </section>
              </section>
              <section className="pt-[6px] text-right text-affes-sm text-gray-900">
                <section className=" w-[8rem] md:w-auto">
                  <p className="mb-[10px]">
                    {defaultStore?.distance} {formatMessage({ id: 'miles', defaultMessage: 'Miles' })}
                  </p>
                  <p className="mb-2">{defaultStore?.storeHours && defaultStore?.storeHours[1]}</p>
                  <p className="mb-[10px]">{defaultStore?.storeHours && defaultStore?.storeHours[0]}</p>
                </section>
              </section>
            </section>
          </section>
          {!loggedIn && <hr className="border-[rgba(0, 0, 0, 0.2)] mt-[21px] mb-[19px]" />}
          {currentItems?.map((channel, id) => {
            return (
              <li key={id}>
                <section>
                  <section className="flex justify-between">
                    <section>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setFindStoreModal(false);
                          const storeLocatorUrl = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                            channel?.name,
                          )}`;
                          router.push(storeLocatorUrl);
                        }}
                        onKeyDown={(ev) => {
                          if (ev.key === 'Enter') {
                            setFindStoreModal(false);
                            const storeLocatorUrl = `${StoreNameURL?.storeLocatorText}${StoreNameURL?.StoreNameURL(
                              channel?.name,
                            )}`;
                            router.push(storeLocatorUrl);
                          }
                        }}
                        className="mb-2 cursor-pointer text-affes-sm font-bold leading-5 text-blue-900"
                      >
                        {channel?.name}
                      </button>

                      <p className="mb-2 text-affes-sm font-semibold text-gray-900">
                        {channel?.address?.streetName} {channel?.address?.city}
                        <br />
                        {channel?.address?.state} {channel?.address?.postalCode}
                      </p>

                      <section className="md:flex md:w-[22rem]">
                        <a href={`tel:${channel?.phoneNumber}`}>
                          <p
                            className="mr-4 cursor-pointer text-affes-sm leading-5 text-blue-900"
                            onClick={handleFindStoreGTMEvent}
                          >
                            {channel?.phoneNumber}
                          </p>
                        </a>

                        <button
                          onClick={async () => {
                            await handleFindStoreGTMEvent();
                            setFindStoreModal(false);
                            if (window) {
                              window.open(
                                `https://www.google.com/maps/dir/?api=1&destination=${channel?.lat},${channel.long}`,
                                '_blank',
                              );
                            }
                          }}
                          className="mr-6 cursor-pointer text-affes-sm leading-5 text-blue-900"
                        >
                          {formatMessage({ id: 'getDirections', defaultMessage: 'Get Directions' })}
                        </button>
                        {loggedIn && channel?.isClickToBrick && (
                          <button
                            onClick={(e) => {
                              handleFindStoreGTMEvent();
                              makeMyStore(e, channel?.key);
                            }}
                            className="cursor-pointer text-affes-sm leading-5 text-blue-900"
                          >
                            {formatMessage({ id: 'makeMyStore', defaultMessage: 'Make This My Store' })}
                          </button>
                        )}
                        {!loggedIn && channel?.isClickToBrick && (
                          <button
                            onClick={() => {
                              handleFindStoreGTMEvent();
                              showLogin(channel?.key);
                            }}
                            type="button"
                            className="cursor-pointer text-affes-sm leading-5 text-blue-900"
                          >
                            {formatMessage({ id: 'makeMyStore', defaultMessage: 'Make This My Store' })}
                          </button>
                        )}
                      </section>
                    </section>
                    <section className="pt-[6px] text-right text-affes-sm text-gray-900">
                      <section className=" w-[8rem] md:w-auto">
                        <p className="mb-[10px]">
                          {channel?.distance} {formatMessage({ id: 'miles', defaultMessage: 'Miles' })}
                        </p>
                        <p className="mb-2">{channel?.storeHours && channel?.storeHours[1]}</p>
                        <p className="mb-[10px]">{channel?.storeHours && channel?.storeHours[0]}</p>
                      </section>
                    </section>
                  </section>
                </section>
                <hr className="border-[rgba(0, 0, 0, 0.2)] mt-[21px] mb-[19px]" />
              </li>
            );
          })}
          {storeList.length > 4 && (
            <ReactPaginate
              nextLabel=""
              onPageChange={handlePageClick}
              pageRangeDisplayed={itemsPerPage}
              marginPagesDisplayed={itemsPerPage}
              pageCount={pageCount}
              previousLabel=""
              breakLabel="..."
              breakLinkClassName="page-link"
              containerClassName="pagination"
              renderOnZeroPageCount={null}
              forcePage={currentPage}
              initialPage={0}
              activeClassName="selected"
            />
          )}
        </>
      )}
    </>
  );
};

export default Store;
