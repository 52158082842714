import React, { useEffect, useState, useCallback } from 'react';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { appWithTranslation } from 'next-i18next';
import { mutate } from 'swr';
const Login = dynamic(() => import('components/commercetools-ui/aafes/Modals/Login/login'));
import RequestTimeoutModal from 'components/commercetools-ui/aafes/Modals/requestTimeoutModal';
import SessionLoggedOutModal from 'components/commercetools-ui/aafes/Modals/sessionLoggedOutModal';
import Toaster from 'components/commercetools-ui/toaster';
import UrlTracker from 'helpers/hooks/urlTracker';
import { FrontasticProvider } from 'frontastic';
import 'tailwindcss/tailwind.css';
import '../styles/app.css';
import '../styles/themes/default.css';
import '../styles/themes/theme1.css';
import '../styles/themes/theme2.css';
import '../styles/themes/theme3.css';
import '../styles/components/slider.css';
import '../styles/components/default-loader.css';
import GTMinitiator from './GTMScript';
import Monetate from './MonetateHeadScript';
import { initializeGbTracker } from 'helpers/utils/gbTracker';
declare global {
  interface Window {
    monetateQ: any;
    monetateT: number;
    GbTracker: any;
  }
}
function FrontasticStarter({ Component, pageProps }: AppProps) {
  const monetate_url = process?.env?.NEXT_PUBLIC_MONETATE_URL;
  const bazaarvoice_url = process?.env?.NEXT_PUBLIC_BAZAARVOICE_URL;
  const dynatrace_url = process?.env?.NEXT_PUBLIC_DYNATRACE_URL;
  const beacon_url = process?.env?.NEXT_PUBLIC_BEACON_URL;
  const beacon_area = process?.env?.NEXT_PUBLIC_BEACON_AREA;
  const router = useRouter();
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState(false);
  const [isSessionExpiredModalOpen, setIsSessionExpiredModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const openTimeoutModal = useCallback(() => {
    setIsTimeoutModalOpen(true);
  }, []);

  initializeGbTracker('aafes', beacon_area);
  const openSessionExpiredModal = async () => {
    sessionStorage.setItem('isSessionExpired', 'true');
    mutate('/action/account/getAccount', { loggedIn: false, account: undefined }, { revalidate: false });
    await mutate('/action/cart/getCart');
    setIsSessionExpiredModalOpen(true);
  };
  useEffect(() => {
    document?.addEventListener('request:timeout', openTimeoutModal);
    return () => {
      document?.removeEventListener('request:timeout', openTimeoutModal);
    };
  }, [openTimeoutModal]);
  useEffect(() => {
    document?.addEventListener('session:expired', openSessionExpiredModal);
    return () => {
      document?.removeEventListener('session:expired', openSessionExpiredModal);
    };
  }, []);
  function closeModal() {
    setIsTimeoutModalOpen(false);
  }
  return (
    <>
      <FrontasticProvider>
        <Script async type="text/javascript" src={`${dynatrace_url}`} crossOrigin="anonymous" />
        <Monetate monetateUrl={monetate_url} />
        <Script
          data-ad-client="ca-pub-1129357587154206"
          async
          crossOrigin="anonymous"
          strategy="afterInteractive"
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
        />
        <Script async crossOrigin="anonymous" src="https://cdn.manh.cloud/selfservice/chatbot/latest/chat.js" />
        <Script async type="text/javascript" src={`${bazaarvoice_url}`} />
        <Script type="text/javascript" src="https://gateway.foresee.com/sites/aafes/production/gateway.min.js" async />
        <GTMinitiator pageProps={pageProps} />
        <Script strategy="beforeInteractive" type="text/javascript" src={`${beacon_url}`} />
        <Component {...pageProps} />
        <UrlTracker />
        <Toaster />
        {isSessionExpiredModalOpen && (
          <SessionLoggedOutModal
            closeModal={() => setIsSessionExpiredModalOpen(false)}
            ctaCallback={() => {
              setIsLoginModalOpen(true);
              setIsSessionExpiredModalOpen(false);
            }}
          />
        )}
        <Login
          loginCallBack={() => {
            router.push(router?.asPath);
          }}
          loginModal={isLoginModalOpen}
          closeModal={() => {
            setIsLoginModalOpen(false);
          }}
        />
      </FrontasticProvider>
      {isTimeoutModalOpen && <RequestTimeoutModal closeModal={closeModal}></RequestTimeoutModal>}
    </>
  );
}
export default appWithTranslation(FrontasticStarter);
