import Link from 'next/link';
import { HiOutlineEye, HiOutlineEyeOff } from 'react-icons/hi';
import { GTM_GENERIC } from 'helpers/constants/aafes';
import { useFormat } from 'helpers/hooks/useFormat';

export const DefaultLoginPage = ({
  usernameError,
  data,
  handleChange,
  handlekeypress,
  inputRef,
  handleInputFocus,
  handleInputBlur,
  closeModal,
  passwordShown,
  passwordError,
  isRememberMeSessionExpired,
  togglePassword,
  continueAsGuestEligible,
  isButtonDisabled,
  toForgotPassword,
  toForgotUsername,
  signIn,
  handleSubmit,
  loading,
}) => {
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  return (
    <section className="py-3">
      <section className="mt-3 mb-7">
        <label id="usernameEmail" className="mb-2 block text-[12px] font-bold text-gray-800" htmlFor="username">
          {formatAccountMessage({ id: 'usernameEmail', defaultMessage: 'Username / Email' })}
        </label>
        <input
          type="text"
          name="username"
          id="username"
          className={`w-full rounded-sm border border-gray-600 ${
            usernameError.length > 0 ? 'border-2 border-red-500' : ''
          }`}
          maxLength={40}
          value={data.username}
          onChange={handleChange}
          onKeyDown={handlekeypress}
          aria-invalid={usernameError.length > 0 ? 'true' : 'false'}
          aria-describedby="usernameError"
          aria-labelledby="usernameEmail"
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        {usernameError && (
          <p id="usernameError" className="mt-[10px] text-sm text-red-900">
            {usernameError}
          </p>
        )}
      </section>
      <section className="mt-5 mb-3">
        <label id="user-password" className="mb-2 block text-[12px] font-bold text-gray-800" htmlFor="password">
          {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
        </label>
        <section className="relative">
          <input
            type={passwordShown ? 'text' : 'password'}
            name="password"
            id="password"
            autoComplete="current-password"
            className={`w-full rounded-sm border border-gray-600 ${
              passwordError.length > 0 ? 'border-2 border-red-500' : ''
            }`}
            value={data.password}
            onChange={handleChange}
            onKeyDown={handlekeypress}
            aria-invalid={passwordError.length > 0 ? 'true' : 'false'}
            aria-describedby="passwordError"
            aria-labelledby="user-password"
            ref={inputRef}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          {passwordError && (
            <p id="passwordError" className="mt-[10px] text-sm text-red-900">
              {passwordError}
            </p>
          )}
          <button
            className="absolute right-5 top-2 flex cursor-pointer items-center text-[10px] text-[#333333]"
            onClick={(event) => {
              event.stopPropagation();
              togglePassword();
            }}
            aria-label={passwordShown ? 'Hide Password' : 'Show Password'}
          >
            {passwordShown ? <HiOutlineEyeOff className="mr-2 text-xl" /> : <HiOutlineEye className="mr-2 text-xl" />}{' '}
            {passwordShown ? 'HIDE' : 'SHOW'}
          </button>
        </section>
      </section>
      {!isRememberMeSessionExpired && (
        <section className="mb-5">
          <input
            type="checkbox"
            name="rememberMe"
            className="mr-2 rounded-sm"
            id="remember-me"
            checked={data.rememberMe}
            onChange={handleChange}
          />{' '}
          <label htmlFor="remember-me" className="text-affes-sm text-gray-900">
            {formatAccountMessage({ id: 'keepSign', defaultMessage: 'Keep me signed in' })}
          </label>
        </section>
      )}
      <section className="mt-9 mb-8">
        <button
          type="button"
          id="signinbtn"
          onClick={!loading ? handleSubmit : null}
          disabled={isButtonDisabled}
          className={
            isButtonDisabled
              ? 'block w-full rounded-sm bg-gray-300 py-2 text-affes-xl font-bold text-gray-500'
              : 'block w-full rounded-sm bg-blue-900 py-2 text-affes-xl font-bold text-white'
          }
          data-gtm-cta
          data-gtm-id={GTM_GENERIC}
        >
          {signIn
            ? formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign In' })
            : formatAccountMessage({ id: 'tryAgain', defaultMessage: 'Try Again' })}
        </button>
      </section>
      <section
        className={`mb-8 flex ${isRememberMeSessionExpired ? 'justify-center' : 'justify-between'} px-[15px] md:px-14 `}
      >
        {!isRememberMeSessionExpired && (
          <button onClick={toForgotUsername}>
            <p className="cursor-pointer text-affes-sm text-blue-900 hover:underline">
              {formatAccountMessage({ id: 'forgotUsername', defaultMessage: 'Forgot Username?' })}
            </p>
          </button>
        )}
        {!isRememberMeSessionExpired ? <>&nbsp;|&nbsp;</> : <></>}
        <button onClick={toForgotPassword}>
          <p className="cursor-pointer  text-affes-sm  text-blue-900 hover:underline">
            {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot Password?' })}
          </p>
        </button>
      </section>
      {!isRememberMeSessionExpired && (
        <section className="mb-5">
          <Link href="/register">
            <button
              onClick={() => closeModal(false)}
              className="block w-full cursor-pointer rounded-sm border border-blue-900 py-2 text-center text-affes-xl font-bold text-blue-900"
              data-gtm-cta
              data-gtm-id={GTM_GENERIC}
            >
              {formatAccountMessage({ id: 'account.create.new', defaultMessage: 'Create New Account' })}
            </button>
          </Link>
        </section>
      )}
      {continueAsGuestEligible && (
        <section className="mb-5">
          <Link href="/scs">
            <button
              type="button"
              onClick={() => closeModal(false)}
              className="block w-full cursor-pointer rounded-sm border border-blue-900 py-2 text-center text-affes-xl font-bold text-blue-900"
            >
              {formatAccountMessage({ id: 'continue.as.guest', defaultMessage: 'Continue As Guest' })}
            </button>
          </Link>
        </section>
      )}
    </section>
  );
};
