import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { IoCloseOutline } from 'react-icons/io5';
import { useFormat } from 'helpers/hooks/useFormat';

function SizeChart({ closeModal, sizeChartURL }) {
  const { formatMessage } = useFormat({ name: 'common' });
  const [isFileTypePDF, setIsFileTypePDF] = useState<boolean>();

  useEffect(() => {
    const fileExtension = sizeChartURL?.split('.')?.pop()?.toLowerCase()?.replace(/\s+/g, '');
    if (fileExtension === 'pdf') {
      setIsFileTypePDF(true);
    } else {
      setIsFileTypePDF(false);
    }
  }, [sizeChartURL]);

  return (
    <>
      <Transition appear show={true} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child enterFrom="opacity-0" enterTo="opacity-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <div className="bg-black fixed inset-0 bg-opacity-25" />
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <section className="absolute inset-0 flex items-center justify-center" onClick={closeModal}>
                  <section
                    className={`absolute max-h-[90vh] max-w-[90%] ${
                      isFileTypePDF ? 'h-[600px] w-full md:h-[90vh]' : ''
                    } hide-scrollbar overflow-y-auto rounded bg-white px-3 py-4 dark:bg-primary-200`}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="mb-3 flex justify-between">
                      <p className="text-xl font-semibold leading-6 text-gray-900">
                        {formatMessage({ id: 'sizeHelp', defaultMessage: 'Size Help' })}
                      </p>
                      <button
                        className="rounded p-0.5 text-black-100 hover:bg-[#717171] hover:text-white"
                        onClick={closeModal}
                      >
                        <IoCloseOutline className="h-6 w-6" />
                      </button>
                    </div>
                    {isFileTypePDF ? (
                      <iframe src={`${sizeChartURL}#zoom=100`} width="100%" height="100%" title="">
                        {' '}
                      </iframe>
                    ) : (
                      <img src={sizeChartURL} alt="Size Chart Link" />
                    )}
                    <div className="mr-1 mt-3 text-right">
                      <button
                        onClick={closeModal}
                        className="w-full rounded border-1 border-blue-900 bg-blue-900 px-5 py-2 text-lg text-white hover:bg-[#3370A1] md:w-auto"
                      >
                        {formatMessage({ id: 'close', defaultMessage: 'Close' })}
                      </button>
                    </div>
                  </section>
                </section>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default SizeChart;
